import React from 'react'
import { Layout } from 'ui/Layout'
import NotFoundSection from 'components/NotFoundSection'

const propTypes = {}

const metatags = {
  slug: '/',
  title: 'Creditas | Page Not Found',
  description:
    'With headquarters in Brazil, Mexico and Spain, Creditas raised more than US$ 314 million from international venture capital funds. View more.',
}

const NotFound = () => {
  return (
    <Layout metatags={metatags}>
      <NotFoundSection />
    </Layout>
  )
}

NotFound.propTypes = propTypes

export default NotFound
