import React from 'react'
import { Container } from '@creditas/layout'
import {
  Content,
  Info,
  Title,
  Subtitle,
  Description,
  Oops,
  Divider,
  Link,
} from './styles'

const propTypes = {}

const NotFoundSection = () => {
  return (
    <section>
      <Container maxWidth="lg">
        <Content>
          <Info>
            <Title>
              <Oops>Oops</Oops>
              <Divider>|</Divider>
              <span>404</span>
            </Title>
            <Subtitle>
              We were unable to find the page you are looking for.
            </Subtitle>
            <Description>
              Go back to <Link href="/">Home</Link>
            </Description>
          </Info>
        </Content>
      </Container>
    </section>
  )
}

NotFoundSection.propTypes = propTypes

export default NotFoundSection
