import { styled, mq, css } from '@creditas/stylitas'
import { fillMediaBase } from 'ui/utils/fillMediaBase'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq({
    flexDirection: fillMediaBase('column-reverse', 'row'),
  })}
`

const Info = styled.div`
  max-width: 496px;

  ${mq({
    marginTop: fillMediaBase('32px', '145px'),
    marginBottom: fillMediaBase('96px', '145px'),
  })}
`

const Title = styled.h1(
  ({ theme }) => css`
    color: ${theme.bColorNeutral90};

    font-style: normal;

    ${mq({
      fotnWeight: fillMediaBase('bold', '300'),
      fontSize: fillMediaBase('36px', '72px'),
      lineHeight: fillMediaBase('45px', '90px'),
      textAlign: fillMediaBase('center', 'left'),
      letterSpacing: fillMediaBase('-1.2px', '0.3px'),
    })}
  `,
)

const Subtitle = styled.h2(
  ({ theme }) => css`
    font-style: normal;
    font-weight: 300;

    ${mq({
      color: fillMediaBase(theme.bColorNeutral60, theme.bColorNeutral90),
      marginTop: fillMediaBase('24px', '64px'),
      fontSize: fillMediaBase('20px', '32px'),
      lineHeight: fillMediaBase('28px', '48px'),
      letterSpacing: fillMediaBase('0.5px'),
    })}
  `,
)

const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.bColorNeutral90};

    width: 100%;
    font-style: normal;
    font-weight: normal;

    ${mq({
      fontSize: fillMediaBase('16px', '20px'),
      lineHeight: fillMediaBase('24px', '28px'),
      letterSpacing: fillMediaBase('0.3px', '0.5px'),
      marginTop: fillMediaBase('24px', '16px'),
    })}
  `,
)

const Oops = styled.span`
  font-weight: bold;
`

const Link = styled.a(
  ({ theme }) => css`
    color: ${theme.bColorPrimaryDefault};

    ${mq({
      fontWeight: fillMediaBase('bold', null),
    })}

    display: inline-block;
    cursor: pointer;
  `,
)

const Divider = styled.span`
  display: inline-block;

  font-style: normal;
  font-weight: 300;

  ${mq({
    margin: fillMediaBase('0 4px', '0 16px'),
  })}
`

export { Content, Info, Title, Subtitle, Description, Oops, Divider, Link }
